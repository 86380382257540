// Libs.
import React from 'react';

// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import UserRegister from '../components/user/user-register';
import crmClientService from '../services/crmClientService';

const UserRegistrationPage = ({location:{search = ''}}) => {
  const qs = new URLSearchParams(search);
  const destination = qs.get('destination') || '/user-login';
  const token = qs.get('t') || '';

  return (
    <Layout>
      <SEO title="User Register" />
      <UserRegister destination={destination} token={token} />
    </Layout>
  );
};

export default UserRegistrationPage;
